import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { resetIdleTime } from '@/modules/auth';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '',
        component: () => import('@/views/frame.vue'),
        children: [
            {
                path: '/',
                name: 'Root',
                component: () => import('@/views/home.vue')
            },
            {
                path: '/tenants',
                name: 'TenantOverview',
                component: () => import('@/views/tenants/tenant-overview.vue')
            }, {
                path: '/tenants/:_id/:displayName?',
                name: 'TenantDetails',
                component: () => import('@/views/tenants/tenant-details.vue')
            },
            {
                path: '/agents',
                name: 'AgentOverview',
                component: () => import('@/views/agents/agent-overview.vue')
            },
            {
                path: '/variables',
                name: 'VariablesOverview',
                component: () => import('@/views/variables/variables-overview.vue')
            },
            {
                path: '/public-api',
                name: 'PublicApiOverview',
                component: () => import('@/views/public-api/public-api-overview.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login.vue'),
        meta: {
            fullscreen: true
        }
    },

    { path: '*', redirect: '/' }
];

const router = new VueRouter({
    mode: 'hash',
    routes
});

router.afterEach(() => {
    resetIdleTime();
});

export default router;
