import { InternalAxiosRequestConfig } from 'axios';

const AUTH_STORAGE_NAME = 'auth-token';
const AUTH_TRANSPORT_COOKIE_NAME = 'tm-att';
const AUTH_RESOURCE_COOKIE_NAME = 'tm-rtc';
const DEFAULT_SESSION_IDLE_TIMEOUT = 8 * 60 * 60 * 1000;

let theToken: string | null;
let lastAction: number = Date.now();

(function initSessionIdleCheck() {
    setInterval(checkSessionIdle, 60 * 1000);
})();

export function setAuthHeader(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    config.headers.setAuthorization(getAuthToken() as string);
    return config;
}

export function getAuthToken(): string | null {
    if (theToken) {
        return theToken;
    }

    if (!checkResourceTokenCookie()) {
        return null;
    }

    if (checkSessionIdle()) {
        theToken = getTokenFromTransportCookie(AUTH_TRANSPORT_COOKIE_NAME);

        if (theToken) {
            putTokenIntoStore(AUTH_STORAGE_NAME, theToken);
        } else {
            theToken = getTokenFromStore(AUTH_STORAGE_NAME);
        }
    }

    return theToken;
}

export function expireLoginAndLogout(): void {
    putTokenIntoStore(AUTH_STORAGE_NAME, null);
    window.location.pathname = '/api/auth/logout';
}

export function initAuthorizationToken(): void {
    const token = getAuthToken();
    let reason = '';
    const isAuthTokenAvailable = token && token.length > 0;

    if (isAuthTokenAvailable) {
        reason = 'expired';
    } else {
        reason = 'no-token';
    }

    const loginUri = `/api/auth/getToken/ui?reason=${reason}&returnUri=${encodeURIComponent(window.location.href)}`;
    window.location.href = loginUri;
}

export function resetIdleTime(): void {
    lastAction = Date.now();
}

function getTokenFromStore(name: string): string | null {
    return window.localStorage.getItem(name);
}

function putTokenIntoStore(name: string, token: string | null): void {
    if (token) {
        window.localStorage.setItem(name, token);
    } else {
        window.localStorage.removeItem(name);
    }
}

function getTokenFromTransportCookie(name: string): string | null {
    const value = getCookieValue(name);

    deleteTransportCookie(name);

    return value;
}

function deleteTransportCookie(name: string): void {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
}

function checkResourceTokenCookie(): boolean {
    return !!getCookieValue(AUTH_RESOURCE_COOKIE_NAME);
}

function getCookieValue(name: string): string | null {
    const regExString = name + '=(.*?)(;|$)';
    const getAuthRegEx = new RegExp(regExString);
    const cookie = document.cookie || '';
    const capture = getAuthRegEx.exec(cookie);

    if (!capture || !capture[1]) {
        return null;
    }
    return decodeURI(capture[1]);
}

function checkSessionIdle() {
    if (lastAction + DEFAULT_SESSION_IDLE_TIMEOUT < Date.now()) {
        expireLoginAndLogout();
        return false;
    }
    return true;
}
