import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify, { colors } from 'vuetify/lib';

import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
        values: {
            complete: 'fa-user-secret',
            cancel: 'fa-user-secret',
            close: 'fa-times',
            delete: 'fa-times',
            clear: 'fa-times',
            success: 'fa-user-secret',
            info: 'fa-user-secret',
            warning: 'fa-user-secret',
            error: 'fa-exclamation-triangle',
            prev: 'fa-angle-left',
            next: 'fa-angle-right',
            checkboxOn: 'fa-check-square',
            checkboxOff: 'far fa-square',
            checkboxIndeterminate: 'fa-minus-square',
            delimiter: 'fa-user-secret',
            sort: 'fa-chevron-up ml-1',
            expand: 'fa-angle-down',
            menu: 'fa-user-secret',
            subgroup: 'fa-angle-down',
            dropdown: 'fa-caret-down',
            radioOn: 'fa-user-secret',
            radioOff: 'fa-user-secret',
            edit: 'fa-user-secret',
            ratingEmpty: 'fa-user-secret',
            ratingFull: 'fa-user-secret',
            ratingHalf: 'fa-user-secret',
            loading: 'fa-sync',
            first: 'fa-user-secret',
            last: 'fa-user-secret',
            unfold: 'fa-user-secret',

            'input-text': 'fa-font',
            'input-number': 'fas fa-plus-square',
            'input-date': 'fa-calendar-day',
            'input-identity': 'fa-users',
            'input-list': 'fa-list',
            'input-remote': 'fa-wifi',
            'input-file': 'fa-file',
            'input-boolean': 'fas fa-toggle-off',
            'input-password': 'fa-key',
            'input-link': 'fa-external-link-alt',
            'input-reference': 'fa-link'
        }
    },
    theme: {
        dark: true,
        options: {
            customProperties: true,
            variations: false
        },
        themes: {
            dark: {
                primary: colors.amber.accent4,
                accent: '#16738F'
            },
            // light: {
            //     primary: '#821550',
            //     secondary: '#646363',
            //     accent: '#16738F'
            // }
        }
    },
    lang: {
        current: 'en',
        locales: { de, en }
    }
});
