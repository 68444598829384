import Vue  from 'vue';
import Vuelidate, { Validation } from 'vuelidate';

import { ValidationProperties, ValidationGroups } from 'vue/types/vue';
import VueI18n, { IVueI18n, TranslateResult } from 'vue-i18n';
import { deepValue, setDeepValue } from '@raccoon/shared';
import { ResourceDefinition, ResourceSchema, TenantDefinition } from '@raccoon/shared';
import { RuleDecl } from 'vue/types/options';
import { required, decimal, maxValue, minValue, helpers, url, email, sameAs, requiredUnless } from 'vuelidate/lib/validators';

Vue.use(Vuelidate);

export type ValidatorInstance = ValidationProperties<Vue> & ValidationGroups & Validation;

export function getComponentValidationMessageBuilder(
    $v: ValidatorInstance,
    $i18n: VueI18n & IVueI18n
): (validationProperty: string, displayName: TranslateResult, validatePristine?: boolean, validatePending?: boolean) => string[] | undefined {
    return (validationProperty: string, displayName: TranslateResult, validatePristine?: boolean, validatePending?: boolean): string[] | undefined => {
        if (!$v) {
            console.warn('Using validation without $v instance... Maybe forgot to provide parentValidator or define validation in component?');
            return;
        }

        const validationContext = $v[validationProperty] ?? deepValue($v, validationProperty);
        // TODO dirty & pending check ;)
        if (validationContext && validationContext.$invalid && (validatePristine || validationContext.$dirty || (validatePending && validationContext.$pending))) {
            const validationMessages: string[] = [];

            const validationRules = Object.keys(validationContext.$params);

            validationRules.forEach((ruleName) => {
                const isValid = validationContext[ruleName];
                if (!isValid) {
                    let translationKey = `validation.${ruleName}`;

                    if (validationContext.$pending) {
                        translationKey += '/pending';
                    }

                    const translationParameter = {
                        prop: validationProperty,
                        displayName,
                        ruleName,
                        value: validationContext.$model,
                        validationContext,
                        ...validationContext.$params[ruleName]
                    };

                    const translation = $i18n.t(translationKey, translationParameter) as string;

                    validationMessages.push(translation ?? displayName ?? ruleName);
                }
            });

            return validationMessages;
        }
    };
}

export const validationMessagesMixin = Vue.extend({
    computed: {
        validationMessages() {
            return getComponentValidationMessageBuilder(this.$v, this.$i18n);
        }
    }
});
export const injectedValidationParentMixin = Vue.extend({
    inject: {
        $v: {
            from: 'parentValidator',
            default: {}
        }
    }
});

const dateStringMaxValue = (displayValue: string, max: Date) =>
    helpers.withParams({ type: 'maxValue', max: displayValue }, (value: number | string) => {
        if (!helpers.req(value)) {
            return true;
        }

        const parsedDate = new Date(value);

        return !isNaN((parsedDate as unknown) as number) && +parsedDate <= +max;
    });

const dateStringMinValue = (displayValue: string, min: Date) =>
    helpers.withParams({ type: 'minValue', min: displayValue }, (value: number | string) => {
        if (!helpers.req(value)) {
            return true;
        }

        const parsedDate = new Date(value);

        return !isNaN((parsedDate as unknown) as number) && +parsedDate >= +min;
    });

export function resourceDefinitionValidator(resourceDefinition: ResourceDefinition): RuleDecl {
    const validations: RuleDecl = {};

    Object.keys(resourceDefinition.fields).forEach((fieldPath) => {
        const fieldDefinition = resourceDefinition.fields[fieldPath];

        let fieldValidation: RuleDecl | null = null;

        if ('required' in fieldDefinition.options && fieldDefinition.options.required) {
            fieldValidation = { required };
        }

        if (fieldDefinition.inputType === 'number') {
            fieldValidation = { decimal, ...fieldValidation };

            if ('lessThan' in fieldDefinition.options && Number.isFinite(fieldDefinition.options.lessThan)) {
                fieldValidation = {
                    maxValue: maxValue(fieldDefinition.options.lessThan as number),
                    ...fieldValidation
                };
            }

            if ('moreThan' in fieldDefinition.options && Number.isFinite(fieldDefinition.options.moreThan)) {
                fieldValidation = {
                    minValue: minValue(fieldDefinition.options.moreThan as number),
                    ...fieldValidation
                };
            }
        }

        if (fieldDefinition.inputType === 'date') {
            if ('lessThan' in fieldDefinition.options && fieldDefinition.options.lessThan) {
                fieldValidation = {
                    maxValue: dateStringMaxValue(fieldDefinition.options.lessThan as string, new Date(fieldDefinition.options.lessThan)),
                    ...fieldValidation
                };
            }

            if ('moreThan' in fieldDefinition.options && fieldDefinition.options.moreThan) {
                fieldValidation = {
                    minValue: dateStringMinValue(fieldDefinition.options.moreThan as string, new Date(fieldDefinition.options.moreThan)),
                    ...fieldValidation
                };
            }
        }

        if (fieldDefinition.inputType === 'link') {
            if ('type' in fieldDefinition.options && fieldDefinition.options.type) {
                if (fieldDefinition.options.type === 'url') {
                    fieldValidation = { url: url, ...fieldValidation };
                } else if (fieldDefinition.options.type === 'mailto') {
                    fieldValidation = { email: email, ...fieldValidation };
                }
            }
        }

        if (fieldDefinition.inputType === 'boolean') {
            if ('required' in fieldDefinition.options && fieldDefinition.options.required) {
                fieldValidation = { boolRequired: sameAs(() => true) };
            }
        }

        if (fieldDefinition.inputType === 'password') {
            if ('required' in fieldDefinition.options && fieldDefinition.options.required) {
                fieldValidation = { newValue: { required: requiredUnless('identifier') } };
            }
        }

        if ('multiValues' in fieldDefinition.options && fieldDefinition.options.multiValues) {
            if (fieldValidation && 'required' in fieldValidation) {
                fieldValidation = { required, $each: { ...fieldValidation } };
            } else {
                fieldValidation = { $each: { ...fieldValidation } };
            }
        }

        if (fieldValidation) {
            setDeepValue(validations, fieldPath, fieldValidation);
        }
    });

    return validations;
}

export function tenantDefinitionValidator(tenantDefinition: TenantDefinition, resourceSchema: ResourceSchema): RuleDecl {
    const _refs: RuleDecl = {};
    const validations: RuleDecl = {
        // ...resourceDefinitionValidator(tenantDefinition),
        _refs
    };

    for (const resource of tenantDefinition.types) {
        _refs[resource] = resourceDefinitionValidator(resourceSchema[resource]);
    }

    return validations;
}
